import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="introduction">
        <p>
          {" "}
          eunomie is a boutique Ai & business economics consultancy, designing +
          implementing Ai & machine learning solutions.
        </p>
        <hr class="intro" />
      </div>
      <div className="mission-statement-zero">
        <p>We start with the data, and go beyond the data</p>
      </div>
      <div className="mission-statement-one">
        <p>
          beyond the data, beyond the models, to understand the business and its
          operational challenges
        </p>
      </div>
      <div className="mission-statement-two">
        <p>
          build dynamic solutions that scale with the business, and adapt to
          economic challenges in the short-run and long-run
        </p>
      </div>
      <div className="mission-statement-three">
        <p>
          eunomie is an Ai & data science consultancy rooted in the fundamentals
          of business analysis & applied economics
        </p>
      </div>
    </div>
  );
};

export default Home;
