import React from "react";
import "./Services.css";
/*import ItemList from "/Users/tulu/Desktop/eunomie_/eunomie-website/ew3/src/components/ItemList";*/
/*import ItemList from "./ItemList";*/
import CustomList from "./CustomList";

const Services = () => {
  return (
    <div className="services">
      <CustomList />
    </div>
  );
};

export default Services;

/* <ItemList />*/
