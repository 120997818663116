import React from "react";
import "./CustomList.css";

function CustomList() {
  return (
    <div className="custom-list">
      <ul>
        <li className="list-item item-1">
          <h2>Solution Architecture</h2>
          <hr class="hr1" />
          <ul className="sub-list">
            <li className="sub-item">
              Understanding & assessing business needs
            </li>
            <li className="sub-item">Evaluate relevant technologies</li>
            <li className="sub-item">Design & implement technical solution</li>
          </ul>
        </li>
        <li className="list-item item-2">
          <h2>Data</h2>
          <hr class="hr2" />
          <ul className="sub-list">
            <li className="sub-item">
              General data assessment & preparing data for Ai & machine learning
            </li>
            <li className="sub-item">Data storage & warehousing solutions</li>
            <li className="sub-item">
              Data pipeline solutions, automating dataflow process
            </li>
            <li className="sub-item">
              Data Visualization, KPI's (Key Performance Indicators)
            </li>
          </ul>
        </li>
        <li className="list-item item-3">
          <h2>Neural Networks & Machine Learning</h2>
          <hr class="hr3" />
          <ul className="sub-list">
            <li className="sub-item">
              NLP (Natural Language Processing), document classification,
              sentiment analysis
            </li>
            <li className="sub-item">
              Computer Vision Models (image & video) object recognition &
              counting
            </li>
            <li className="sub-item">
              Time-series, Forecasting, predictive models
            </li>

            <li className="sub-item">Classification, segmentation models</li>

            <li className="sub-item">Anomaly detection, clustering models</li>
          </ul>
        </li>
        <li className="list-item item-4">
          <h2>Federated Learning</h2>
          <hr class="hr4" />
          <ul className="sub-list">
            <li className="sub-item">
              Decentralized Data Processing: Data on local
              devices/servers/machines, eliminating centralized data storage
            </li>
            <li className="sub-item">
              Enhanced Privacy and Security: Sensitive data is not shared or
              transmitted, reduce risk of data breaches.
            </li>
            <li className="sub-item">
              Efficient Model Training: Learning from multiple
              devices/servers/machines, improving model accuracy
            </li>
            <li className="sub-item">
              Scalable and Flexible: Easily adaptable to different scales and
              types of data
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default CustomList;
