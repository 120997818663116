import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <p>Let's have a chat about your data and business objectives.</p>
      <p>
        We are here to help your business take advantage of the various business
        productivity tools that Ai & machine learning have to offer.
      </p>
      <p className="email">
        Email: <a href="mailto:info@eunomie.ca">info@eunomie.ca</a>
      </p>
    </div>
  );
};

export default Contact;
