import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>Eunomie Inc. specializes in implementing Machine Learning and AI tools to improve your business processes.</p>
    </div>
  );
}

export default About;

